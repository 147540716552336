import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Alert, AlertProps, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { appConfig } from "../appConfig";
import { loginRequest } from "../authConfig";
import InputMask from "react-input-mask";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridSlots,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { AppRole, getAppRole } from "../utils/AppRole";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { ProvinceList } from "../models/Constants";

interface AddToolbarProps {
  setIsEditMode: (isOpen: boolean) => void;
  setRowEditId: (id: string) => void;
}

function AddToolbar(props: AddToolbarProps) {
  const { setRowEditId, setIsEditMode } = props;
  const handleClick = () => {
    setIsEditMode(true);
    setRowEditId("");
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add
      </Button>
    </GridToolbarContainer>
  );
}

export function Carriers() {
  const initialCarrier = {
    name: ""
  };
  const { instance } = useMsal();
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowEditId, setRowEditId] = useState("");
  const [rows, setRows] = useState<any>([]);
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [carrier, setCarrier] = useState(initialCarrier);
  const [selectedProvince, setSelectedProvince] = useState("");

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleEditClick = (id: string) => () => {
    setIsEditMode(true);
    setRowEditId(id);

    const url = `${appConfig.api.url}/api/carriers/${id}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response["authorizedOCNs"]) {
          response["ocns"] = response["authorizedOCNs"].join(",");
        }
        setCarrier(response);
        setSelectedProvince(response.province);
      });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleCloseDialog = () => {
    setIsEditMode(false);
    setCarrier(initialCarrier);
    setSelectedProvince("")
  }

  const handleUpdate = (name, value, isCheckbox = false, isProvince = false) => {
    setCarrier(props => { return { ...props, [name]: isCheckbox ? value === "on" : value } });
    if (isProvince) {
      setSelectedProvince(value);
    }
  }

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "name",
      headerName: "Entity Name",
      width: 250,
    },
    {
      field: "groupId",
      headerName: "Group Id",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      width: 200,
      valueFormatter: (value) =>
        dayjs(value as string).format('YYYY-MM-DD')
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleEditClick(id.toString())}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);
        // check Role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role !== AppRole.Administrator) {
          instance.logoutRedirect();
        }

        fetch(`${appConfig.api.url}/api/carriers`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setRows(response);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                }
              },
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              }
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            loading={loading}
            slots={{
              toolbar: AddToolbar as GridSlots["toolbar"],
            }}
            slotProps={{
              toolbar: { setRowEditId, setIsEditMode },
            }}
          />
        </Box>
        <Dialog
          open={isEditMode}
          onClose={handleCloseDialog}
          className="form"
          PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              let data = carrier;

              // Populate dates
              data["authorizedOCNs"] = carrier["ocns"].split(",").map(item => item.trim());
              const url = rowEditId ? `${appConfig.api.url}/api/carriers/${rowEditId}` : `${appConfig.api.url}/api/carriers`;
              const method = rowEditId ? "PUT" : "POST";
              if (!rowEditId) {
                data["id"] = uuid();
              }

              fetch(url, {
                method: method,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              })
                .then((response) => {
                  if (response.ok) {
                    setIsEditMode(false);

                    // If adding new carrier add new row to the grid 
                    if (!rowEditId) {
                      setRows((oldRows) => [
                        ...oldRows,
                        {
                          id: carrier["id"],
                          name: carrier["name"],
                          groupId: carrier["groupId"],
                          createdAt: new Date(),
                        },
                      ]);
                    }
                    setCarrier(initialCarrier);
                    setSnackbar({
                      children: "Carrier successfully saved",
                      severity: "success",
                    });
                  } else {
                    setSnackbar({ children: "Failed to save data", severity: "error" });
                  }
                })
                .catch((error) => {
                  setSnackbar({ children: error.message, severity: "error" });
                });
            },
          }}
        >
          <DialogTitle>{rowEditId ? "Edit" : "Add"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Fill up the form to create or edit Carrier
            </DialogContentText>
            <Grid container spacing={1} className="container">
              <Grid item xs={6}>
                <InputLabel>Entity Name</InputLabel>
                <TextField
                  required
                  name="name"
                  value={carrier["name"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Group Id</InputLabel>
                <TextField
                  required
                  name="groupId"
                  value={carrier["groupId"]}
                  disabled={!!rowEditId}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Contact Name</InputLabel>
                <TextField
                  required
                  name="contactName"
                  value={carrier["contactName"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Title</InputLabel>
                <TextField
                  required
                  name="title"
                  value={carrier["title"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Address</InputLabel>
                <TextField
                  required
                  name="address"
                  value={carrier["address"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>City</InputLabel>
                <TextField
                  required
                  name="city"
                  value={carrier["city"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Province</InputLabel>
                <Select
                  required
                  name="province"
                  value={selectedProvince}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value, false, true)}
                  fullWidth
                >
                  {ProvinceList.map((o, i) => (
                    <MenuItem value={o} key={i}>{o}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>PostalCode</InputLabel>
                <TextField
                  required
                  name="postalCode"
                  value={carrier["postalCode"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  inputProps={{ maxLength: 7 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Telephone</InputLabel>
                <InputMask mask="999-999-9999" value={carrier["phone"]} disabled={false} maskChar="x" onChange={(e) => handleUpdate(e.target.name, e.target.value)}>
                  {() => (
                    <TextField
                      required
                      name="phone"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Facsimile</InputLabel>
                <InputMask mask="999-999-9999" value={carrier["fax"]} disabled={false} maskChar="x" onChange={(e) => handleUpdate(e.target.name, e.target.value)}>
                  {() => (
                    <TextField
                      name="fax"
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Email</InputLabel>
                <TextField
                  name="email"
                  type="email"
                  required
                  value={carrier["email"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Authorized OCNs (coma separated)</InputLabel>
                <TextField
                  required
                  name="ocns"
                  value={carrier["ocns"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Dialog>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </AuthenticatedTemplate >
    </>
  );
}
