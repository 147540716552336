import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { appConfig } from "../appConfig";
import { loginRequest } from "../authConfig";
import { AppRole, getAppRole } from "../utils/AppRole";
import UserInfo from "../models/UserInfo";
import { VisuallyHiddenInput } from "../components/VisuallyHiddenInput";

export function Upload() {
  const initialProps = {
    authorizedOCNs: [""],
  };
  const allowedExtensions = [".xls", ".csv", ".xlsx"];
  const [isInProgress, setIsInProgress] = React.useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [accessToken, setAccessToken] = React.useState<string>("");
  const [props, setProps] = useState(initialProps);
  const { instance } = useMsal();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);
        // Verify role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role !== AppRole.User) {
          instance.logoutRedirect();
        }

        // Get user info
        fetch(`${appConfig.api.url}/api/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setUserInfo(response);
            if (response.carrier) {
              setProps({ ...props, ...response.carrier });
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance]);

  const handleUpdate = (name, value) => {
    setProps(props => { return { ...props, [name]: value } })
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInProgress(true);
    if (!props["ocn"]) {
      setSnackbar({
        children: `Please select OCN.`,
        severity: "error",
      });
      setIsInProgress(false);
      return;
    }

    const fileList = event.target.files;
    if (fileList) {
      const file = fileList[0];
      const fileExtension = file.name.split(".").pop();
      if (!fileExtension || !allowedExtensions.includes(`.${fileExtension}`)) {
        setSnackbar({
          children: `Only ${allowedExtensions.join(", ")} files are allowed.`,
          severity: "error",
        });
        setIsInProgress(false);
        return;
      }

      if (file.size > 10485760) {
        setSnackbar({
          children: "File size should be less than 10MB.",
          severity: "error",
        });
        setIsInProgress(false);
        return;
      }

      // Create FormData object
      const formData = new FormData();
      formData.append("ocn", props["ocn"]);
      formData.append("files", file);

      // Send the file to the server
      fetch(`${appConfig.api.url}/api/files`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((response) => {
          if (response.status !== 200) {
            setSnackbar({
              children: "Error uploading file",
              severity: "error",
            });
          } else {
            setSnackbar({
              children: "File successfully saved",
              severity: "success",
            });
          }
          setIsInProgress(false);
        })
        .catch((error) => {
          setIsInProgress(false);
          setSnackbar({
            children: "Error uploading file",
            severity: "error",
          });
        });
    }
  };

  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ width: "50%" }} className="form">
          <Typography variant="h6" align="center">NRUF</Typography>
          <Grid container spacing={2} className="container">
            <Grid item xs={12}>
              <InputLabel>OCN</InputLabel>
              <Select
                name="ocn"
                value={props["ocn"]}
                onChange={(e) => handleUpdate(e.target.name, e.target.value)}
              >
                <MenuItem value="">
                  <em>Select One</em>
                </MenuItem>
                {props.authorizedOCNs.map((o, i) => (
                  <MenuItem value={o} key={i}>{o}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button
                component="label"
                disabled={isInProgress}
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  onChange={onFileChange}
                  accept={allowedExtensions.join(",")}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </AuthenticatedTemplate>
    </>
  );
}
