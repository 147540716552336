import { jwtDecode } from "jwt-decode";
import { appConfig } from "../appConfig";

export enum AppRole {
  Guest = "Guest",
  User = "User",
  Administrator = "Administrator",
}

export function getAppRole(token: string | undefined): AppRole {
  if (token) {
    const decoded = jwtDecode(token);
    const groups = decoded["groups"] as string[];
    if (groups && groups.length > 0) {
      if (groups[0] === appConfig.auth.groupId) {
        return AppRole.Administrator;
      } else {
        return AppRole.User;
      }
    }
  }
  return AppRole.Guest;
}
