import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { loginRequest } from "../authConfig";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
import { appConfig } from "../appConfig";
import { useNavigate } from "react-router-dom";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { v4 as uuid } from "uuid";
import { AppRole, getAppRole } from "../utils/AppRole";
import UserInfo from "../models/UserInfo";
import { FileUploader } from "../components/FileUploader";
import { ContactInformation } from "../components/ContactInformation";
import { FormType } from "../models/Constants";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export function FormOne() {
  const initialProps = {
    id: "",
    representative: "",
    includePorterNumbers: "No",
    birrdsEntered: "Yes",
    certificationRequired: "Yes",
    city: "",
    province: "",
    postalCode: "",
    applicationType: "CO Code Assignment",
    authorizedOCNs: [""],
    lata: "888",
    entityName: "",
    attestationNumbers: true,
    attestationResources: true,
    attestationCarrier: true,
    npaJeopardyCondition: "No",
    codeAssignmentType: "None",
    codeReservationType: "None",
    codeUniquePurposeType: "Code is necessary for distinct routing, rating or billing purposes",
  };
  const [appendixB, setAppendixB] = useState("");
  const [attachments, setAttachments] = useState("");
  const [isFileBInProgress, setIsFileBInProgress] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const form = useRef();
  const [certificationRequiredYes, setCertificationRequiredYes] = useState(true);
  const [certificationRequiredNo, setCertificationRequiredNo] = useState(false);
  const [portedNumbersCOCodesYes, setPortedNumbersCOCodesYes] = useState(false);
  const [portedNumbersCOCodesNo, setPortedNumbersCOCodesNo] = useState(false);
  const [props, setProps] = useState(initialProps);
  const [daysOut, setDaysOut] = useState(90);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  useEffect(() => {
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);

        // Verify role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role !== AppRole.User) {
          instance.logoutRedirect();
        }

        // Get user info
        fetch(`${appConfig.api.url}/api/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setUserInfo(response);
            if (response.carrier) {
              setProps({ ...props, ...response.carrier });
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance]);

  // Add validation rules
  useEffect(() => {
    ValidatorForm.addValidationRule("switchRequired", (value) => {
      return value && /^[A-Z0-9]{11}$/.test(value.toUpperCase());
    });

    return () => {
      ValidatorForm.removeValidationRule("switchRequired");
    }
  });

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleUpdate = (name, value, isCheckbox = false) => {
    setProps(props => { return { ...props, [name]: isCheckbox ? value === "on" : value } })
  }

  const handleSwitchUpdate = (name, value) => {
    setProps(props => { return { ...props, [name]: value.toUpperCase() } })
  }

  const handleYesNoVisibilityClick = (e, trueSetter, falseSetter) => {
    trueSetter(e.target.checked);
    falseSetter(!e.target.checked)
  }

  const handleEffectiveDateChange = (effectiveDate) => {
    const eDate = dayjs(effectiveDate);
    const tDate = dayjs(new Date());
    const dayDiff = eDate.diff(tDate, 'day');
    setDaysOut(dayDiff + 1);
  }

  const onAppendixBUpload = (fileResponse: any[]) => {
    if (fileResponse.length > 0) {
      setAppendixB(fileResponse[0].fileName);
      setProps(props => { return { ...props, ['appendixB']: fileResponse[0].storageFileName } })
    }
  }

  const onAttachmentsUpload = (fileResponse: any[]) => {
    if (fileResponse.length > 0) {
      setAttachments(`${fileResponse.length} file(s) uploaded`);
      setProps(props => { return { ...props, ['files']: fileResponse } })
    }
  }

  // Arrays that control show/hide logic for sections
  const section14ShowTypes = ['CO Code Assignment', 'Reservation', 'Transfer'];
  const section15AssignmentShowTypes = ['CO Code Assignment'];
  const section15ReservationShowTypes = ['Reservation'];
  const section15InfoChangeShowTypes = ['Information Change', 'Transfer'];
  const section15ReturnShowTypes = ['Return'];

  const handleErrors = (errors) => {
    const input = document.querySelector(
      `input[name=${errors[0].props.name}]`
    ) as HTMLElement;

    if (input) {
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      input.focus();
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let data = props;

    // Populate dates
    data["id"] = uuid();
    data["signDate"] = formData.get("signDate");
    data["applicationDate"] = dayjs(new Date());
    data["effectiveDate"] = formData.get("effectiveDate");
    data["entityName"] = data["name"];

    fetch(`${appConfig.api.url}/api/formone`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        let state = response;
        state.type = FormType.FormOne;
        state.applicationType = props["applicationType"];
        state.npas = props["npas"];
        state.coCodePreferences = props["coCodePreferences"];
        state.exchangeArea = props["exchangeArea"];
        state.nxxReturned = props["npaNxxReturned"];
        state.nxxChanged = props["informationChangeAffected"];
        navigate("/thankyou", { state: state });
      })
      .catch((error) => {
        setSnackbar({ children: error.message, severity: "error" });
      });
  };

  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ width: "75%" }} className="form">
          <ValidatorForm ref={form} onSubmit={handleSubmit} onError={errors => handleErrors(errors)}>
            <Typography variant="h4" align="center">
              Part 1 - CO Code Assignment, Reservation, Information Change, or
              Return
            </Typography>
            <Typography component={"span"} variant="body1">
              <p>
                This Part 1 form is to be used by a Code Applicant or Code
                Holder to apply for a CO Code Assignment, Reservation,
                Information Change, or Return. Use one Part 1 form for each CO
                Code (NXX) requested. One Part 1 form may be used to make the
                same information change for more than one CO Code or to return
                more than one CO Code. Mail, fax, or e-mail the completed form
                to the Canadian Numbering Administrator (CNA). The preferred
                method of submission is by e-mail.
              </p>
              <p>
                As of the date of issue of this form, the CNA contact
                information is:
              </p>
              <p>
                Telephone: (+1) 613 702-0016
                <br />
                Facsimile: (+1) 613 702-0017
                <br />
                E-mail:{" "}
                <a href="mailto:COCodeApps@cnac.ca">COCodeApps@cnac.ca</a>
                <br />
                Postal address: 880 Taylor Creek Dr, Rm 102, Orleans, ON K4A 0Z9
              </p>
              <p>
                See{" "}
                <a
                  href="http://www.cnac.ca/about/contact_us.htm"
                  target="_blank"
                >
                  http://www.cnac.ca/about/contact_us.htm
                </a>{" "}
                for updates to CNA contact information.
              </p>
              <p>
                If this is the first time that you have submitted a form to the
                CNA that must be signed by an Authorized Representative, please
                send a signed facsimile or a paper copy of this form to the CNA
                in addition to or instead of the e-mail submission.
              </p>
              <p>
                I hereby certify that the following information is true and
                accurate to the best of my knowledge and that this application
                has been prepared in accordance with the currently applicable
                version of the Canadian Central Office Code (NXX) Assignment
                Guidelines.
              </p>
            </Typography>
            <Grid container spacing={2} className="container">
              <Grid item xs={12}>
                <InputLabel>Type of Application:</InputLabel>
                <RadioGroup name="applicationType" defaultValue={props["applicationType"]}>
                  <FormControlLabel
                    value="CO Code Assignment"
                    control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                    label="CO Code Assignment"
                  />
                  <FormControlLabel
                    value="Information Change"
                    control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                    label="Information Change"
                  />
                  <FormControlLabel
                    value="Reservation"
                    control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                    label="Reservation"
                  />
                  <FormControlLabel
                    value="Return"
                    control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                    label="Return"
                  />
                  <FormControlLabel
                    value="Transfer"
                    control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                    label="Transfer"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Typography variant="h5">1.0 GENERAL INFORMATION</Typography>
            <Typography variant="h6">1.1 Contact information:</Typography>
            <ContactInformation props={{ ...props }} />

            <Typography variant="h6">1.2 NPA(s):</Typography>
            <Grid container spacing={2} className="container">
              <Grid item xs={4}>
                <InputLabel>NPA(s)</InputLabel>
                <TextValidator
                  name="npas"
                  value={props["npas"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>LATA</InputLabel>
                <TextValidator
                  name="lata"
                  value={props["lata"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>OCN</InputLabel>
                <SelectValidator
                  name="ocn"
                  value={props["ocn"]}
                  validators={["required"]}
                  errorMessages={["OCN is required"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select One</em>
                  </MenuItem>
                  {props.authorizedOCNs.map((o, i) => (
                    <MenuItem value={o} key={i}>{o}</MenuItem>
                  ))}
                </SelectValidator>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  Switch Identification (Switching Entity / POI){" "}
                  <CustomWidthTooltip title="This is the 11 character iconectiv® COMMON LANGUAGE® CLLITM of the Code Applicant's Switching Entity/POI (physical or virtual). (CLLI is a trademark of iconectiv LLC and COMMON LANGUAGE is a registered trademark of iconectiv LLC).">
                    <InfoIcon sx={{ fontSize: 24, verticalAlign: "top" }} />
                  </CustomWidthTooltip>
                </InputLabel>
                <TextValidator
                  name="switchIdentification"
                  className="upper"
                  value={props["switchIdentification"]}
                  onChange={(e) => handleSwitchUpdate(e.target.name, e.target.value)}
                  validators={["switchRequired"]}
                  errorMessages={["11 characters value is required"]}
                  inputProps={{ maxLength: 11 }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>City or Wire Center Name</InputLabel>
                <TextValidator
                  name="cityWireCenterName"
                  value={props["cityWireCenterName"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>
                  Exchange Area{" "}
                  <CustomWidthTooltip title="The Exchange Area on this form and on the Appendix B worksheet shall be a tariffed Exchange Area and shall be the Exchange Area where the CO Code will be or is assigned, rather than the Exchange Area containing the Switch Identification (Switching Entity/POI) CLLI, which may be the same or different. The name of the Exchange Area is generally the same as the name of the Rate Center.">
                    <InfoIcon sx={{ fontSize: 24, verticalAlign: "top" }} />
                  </CustomWidthTooltip>{" "}
                  <Typography variant="caption">
                    <a href='https://www.cnac.ca/reference_data/canadian_exchange_area_listing.htm' target='_blank'>More info</a>
                  </Typography>
                </InputLabel>
                <TextValidator
                  name="exchangeArea"
                  value={props["exchangeArea"]}
                  onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Typography variant="h6">1.3 Dates:</Typography>
            <Grid container spacing={2} className="container">
              <Grid item xs={3}>
                <InputLabel>Application Date</InputLabel>
                <DatePicker
                  disabled={true}
                  name="applicationDate"
                  format="YYYY-MM-DD"
                  defaultValue={dayjs(new Date())}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>
                  Requested Effective Date{" "}
                  <CustomWidthTooltip title="For a CO Code assignment, the requested Effective Date shall be no earlier than 66 days and no later than 6 months after the date of application. The requested Effective Date for a CO Code reservation shall be no more than 12 months after the date of application.">
                    <InfoIcon sx={{ fontSize: 24, verticalAlign: "top" }} />
                  </CustomWidthTooltip>
                </InputLabel>
                <DatePicker
                  name="effectiveDate"
                  format="YYYY-MM-DD"
                  onChange={(e) => handleEffectiveDateChange(e)}
                  defaultValue={dayjs(new Date()).add(90, 'day')}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  <InputLabel>Days Out</InputLabel>
                  <span>{daysOut}</span>
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="h6">
              1.4 Details relating to CO Code Assignment or Reservation:{" "}
              <Tooltip title="Completion of section 1.4 is not required for an Information Change or Return.">
                <InfoIcon sx={{ fontSize: 24, verticalAlign: "top" }} />
              </Tooltip>
            </Typography>
            {/* Display for Assignment/Reservation/Transfer, hide (with note?) for Change/Return */}
            {section14ShowTypes.includes(props["applicationType"]) ? (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <InputLabel>
                    a) In accordance with Telecom Regulatory Policy CRTC 2024-26,{" "}
                    <br />
                    an attestation is required from applicants and their
                    authorized representative(s) <br />
                    requesting geographical NANP numbers. I attest:
                  </InputLabel>
                  <FormControlLabel
                    control={<Checkbox name="attestationNumbers" required />}
                    label="the newly assigned numbers will be used only for services that
                require geographic NANP numbers;"
                  />
                  <FormControlLabel
                    control={<Checkbox name="attestationResources" required />}
                    label="resources other than NANP geographic resources (such as
                non-geographic numbers or dummy numbers) cannot be used instead;
                and,"
                  />
                  <FormControlLabel
                    control={<Checkbox name="attestationCarrier" required />}
                    label="the carrier does not have unused numbering resources from
                previous assignments that can be used instead."
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>b) Type of Entity</InputLabel>
                  <RadioGroup name="entityType" defaultValue={props["entityType"]}>
                    <FormControlLabel
                      value="Local Exchange Carrier"
                      control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Local Exchange Carrier"
                    />
                    <FormControlLabel
                      value="Wireless Carrier"
                      control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Wireless Carrier"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Other"
                    />
                  </RadioGroup>
                  {props["entityType"] == "Other" &&
                    <TextValidator name="entityTypeOtherText" value={props["entityTypeOtherText"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)} validators={["required"]}
                      errorMessages={["this field is required"]} />
                  }
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    c) Type of service for which code is being requested
                  </InputLabel>
                  <TextValidator name="serviceType" validators={["required"]}
                    value={props["serviceType"]}
                    onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    errorMessages={["this field is required"]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    d) Is certification or authorization required to provide this
                    type of service in the relevant geographic area?
                  </InputLabel>
                  <RadioGroup name="certificationRequired" defaultValue={props["certificationRequired"]}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} onClick={(e) => handleYesNoVisibilityClick(e, setCertificationRequiredYes, setCertificationRequiredNo)} />}
                      label="Yes"
                    />
                    <FormControlLabel value="No" control={<Radio
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)} onClick={(e) => handleYesNoVisibilityClick(e, setCertificationRequiredNo, setCertificationRequiredYes)} />} label="No" />
                  </RadioGroup>
                </Grid>
                {certificationRequiredNo &&
                  <Grid item xs={12}>
                    <InputLabel>If no, explain</InputLabel>
                    <TextValidator name="noCertificationExplanation" value={props["noCertificationExplanation"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)} validators={["required"]}
                      errorMessages={["this field is required"]} fullWidth />
                  </Grid>
                }
                {certificationRequiredYes &&
                  <Grid item xs={12}>
                    <InputLabel>
                      If yes, indicate type and date of certification or
                      authorization
                      <br />
                      (e.g., letter of authorization, license, Certificate of Public
                      Convenience & Necessity (CPCN), tariff, etc.)
                      <br />
                      or provide other explanation:
                    </InputLabel>{" "}
                    <Typography variant="caption">
                      <a href="https://www.cnac.ca/co_codes/COCode_Part1_section_1.4d_CertificationReferences.pdf" target="_blank">More info on providing Certification reference</a>
                    </Typography>
                    <TextValidator name="yesCertificationExplanation" value={props["yesCertificationExplanation"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)} validators={["required"]}
                      errorMessages={["this field is required"]} fullWidth />
                  </Grid>
                }
                <Grid item xs={12}>
                  <InputLabel>e) CO Code (NPA-NXX) preference(s)</InputLabel>
                  <TextValidator name="coCodePreferences" value={props["coCodePreferences"]}
                    onChange={(e) => handleUpdate(e.target.name, e.target.value)} validators={["required"]}
                    errorMessages={["this field is required"]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>f) Undesirable CO Code(s) (NPA-NXX(s))</InputLabel>
                  <TextValidator name="undesirableCoCodes" value={props["undesirableCoCodes"]}
                    onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    errorMessages={["this field is required"]} fullWidth />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} className="container-no-border">
                <Typography variant="body1">Not required</Typography>
              </Grid>
            )}

            <Typography variant="h6">1.5 Type of Application:</Typography>
            {section15AssignmentShowTypes.includes(props["applicationType"]) && (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <InputLabel>CO Code Assignment</InputLabel>
                  <RadioGroup name="codeAssignmentType" defaultValue={props["codeAssignmentType"]}>
                    <FormControlLabel
                      control={<Radio value="Initial Code for new Switching Entity/POI"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Initial Code for new Switching Entity/POI"
                    />
                    <FormControlLabel
                      control={<Radio value="Additional Code for Growth"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Additional Code for Growth (Applicant must complete Section 1.6 and Appendix B)"
                    />
                    <FormControlLabel
                      control={<Radio value="Additional Code for Unique Purpose"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Additional Code for Unique Purpose (Applicant must complete Section 1.7)"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
            {section15ReservationShowTypes.includes(props["applicationType"]) && (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <InputLabel>
                    CO Code Reservation{" "}
                    <CustomWidthTooltip title="A CO Code that was reserved for an Applicant is not assigned to the Applicant, and must not be activated unless the Code Applicant has submitted a new Part 1 Request for a CO Code Assignment and the CO Code has been assigned to the Applicant">
                      <InfoIcon sx={{ fontSize: 24, verticalAlign: "top" }} />
                    </CustomWidthTooltip>
                  </InputLabel>
                  <RadioGroup name="codeReservationType" defaultValue={props["codeAssigcodeReservationTypenmentType"]}>
                    <FormControlLabel
                      control={<Radio value="Initial Code for new Switching Entity/POI"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Initial Code for new Switching Entity/POI"
                    />
                    <FormControlLabel
                      control={
                        <Radio value="Additional Code for Growth"
                          onChange={(e) => handleUpdate(e.target.name, e.target.value)} />
                      }
                      label="Additional Code for Growth (Applicant must complete Section 1.6)"
                    />
                    <FormControlLabel
                      control={
                        <Radio value="Additional Code for Unique Purpose"
                          onChange={(e) => handleUpdate(e.target.name, e.target.value)} />
                      }
                      label="Additional Code for Unique Purpose (Applicant must complete Section 1.7)"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
            {section15InfoChangeShowTypes.includes(props["applicationType"]) && (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <InputLabel>
                    Information Change: NPA-NXX(s) affected{" "}
                  </InputLabel>
                  <TextValidator name="informationChangeAffected" value={props["informationChangeAffected"]}
                    onChange={(e) => handleUpdate(e.target.name, e.target.value)} validators={["required"]}
                    errorMessages={["this field is required"]} fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Type of Change</InputLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="ocnTypeofChange" value={props["ocnTypeofChange"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value, true)} />}
                      label="OCN"
                    />
                    <FormControlLabel
                      control={<Checkbox name="switchingEntityTypeofChange" value={props["switchingEntityTypeofChange"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value, true)} />}
                      label="Switching Entity / POI"
                    />
                    <FormControlLabel
                      control={<Checkbox name="effectiveDateTypeofChange" value={props["effectiveDateTypeofChange"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value, true)} />}
                      label="Effective Date"
                    />
                    <FormControlLabel
                      control={<Checkbox name="exchangeAreaTypeofChange" value={props["exchangeAreaTypeofChange"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value, true)} />}
                      label="Exchange Area"
                    />
                    <FormControlLabel
                      control={<Checkbox name="otherTypeofChange" value={props["otherTypeofChange"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value, true)} />}
                      label="Other"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={8}>
                  <InputLabel>Details (provide attachment if needed)</InputLabel>
                  <FormGroup>
                    <TextField
                      className="narrow"
                      name="ocnTypeofChangeDetails"
                      value={props["ocnTypeofChangeDetails"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    ></TextField>
                    <TextField
                      className="narrow"
                      name="switchingEntityTypeofChangeDetails"
                      value={props["switchingEntityTypeofChangeDetails"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    ></TextField>
                    <TextField
                      className="narrow"
                      name="effectiveDateTypeofChangeDetails"
                      value={props["effectiveDateTypeofChangeDetails"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    ></TextField>
                    <TextField
                      className="narrow"
                      name="exchangeAreaTypeofChangeDetails"
                      value={props["exchangeAreaTypeofChangeDetails"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    ></TextField>
                    <TextField
                      className="narrow"
                      name="otherTypeofChangeDetails"
                      value={props["otherTypeofChangeDetails"]}
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    ></TextField>
                  </FormGroup>
                </Grid>
              </Grid>
            )}
            {section15ReturnShowTypes.includes(props["applicationType"]) && (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <InputLabel>Return of CO Code(s)</InputLabel>
                  <InputLabel>
                    NPA-NXX(s) being returned (provide attachment if needed)
                  </InputLabel>
                  <TextValidator name="npaNxxReturned" validators={["required"]}
                    value={props["npaNxxReturned"]}
                    onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    errorMessages={["this field is required"]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    Were these CO Code(s) entered into BIRRDS?
                  </InputLabel>
                  <RadioGroup name="birrdsEntered" defaultValue={props["birrdsEntered"]}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Yes"
                    />
                    <FormControlLabel value="No" control={<Radio
                      onChange={(e) => handleUpdate(e.target.name, e.target.value)} />} label="No" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    Do these CO Code(s) include any ported numbers or pending
                    ports?
                  </InputLabel>
                  <RadioGroup name="includePorterNumbers" defaultValue={props["includePorterNumbers"]}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} onClick={(e) => handleYesNoVisibilityClick(e, setPortedNumbersCOCodesYes, setPortedNumbersCOCodesNo)} />}
                      label="Yes"
                    />
                    <FormControlLabel value="No" control={<Radio onChange={(e) => handleUpdate(e.target.name, e.target.value)} onClick={(e) => handleYesNoVisibilityClick(e, setPortedNumbersCOCodesNo, setPortedNumbersCOCodesYes)} />} label="No" />
                  </RadioGroup>
                  {portedNumbersCOCodesYes &&
                    <>
                      <InputLabel>
                        If yes, list CO Code(s) with ported numbers or pending ports
                      </InputLabel>
                      <TextField name="portedNumbersCOCodes"
                        value={props["portedNumbersCOCodes"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                      />
                    </>
                  }
                </Grid>
              </Grid>
            )}

            <Typography variant="h6">
              1.6 Additional CO Code for Growth (See Section 4.2.1 of the
              Guidelines):
            </Typography>
            {props["codeAssignmentType"] == "Additional Code for Growth" || props["codeReservationType"] == "Additional Code for Growth" ? (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <Typography component={"span"} variant="body1">
                    The criteria for assignment of an Additional Code for Growth
                    to a Switching Entity/POI are that:
                    <ul>
                      <li>
                        existing telephone numbers reported as available for
                        assignment includes either all telephone numbers assigned
                        within one Switching Entity/POI per unique Exchange Area
                        or all telephone numbers assigned within the Exchange
                        Area; and
                      </li>
                      <li>
                        existing telephone numbers are projected to exhaust within
                        12 months where no Jeopardy Condition exists, or, where a
                        Jeopardy Condition exists, within 4 months or other period
                        specified in an approved Jeopardy Contingency Plan.
                      </li>
                    </ul>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Select the applicable situation below</InputLabel>
                  <RadioGroup name="npaJeopardyCondition" defaultValue={props["npaJeopardyCondition"]}>
                    <FormControlLabel
                      sx={{ mb: 2 }}
                      control={<Radio value="No"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="No NPA Jeopardy Condition: I hereby certify that the telephone numbers in existing CO Code(s) as defined above for the Switching Entity/POI or Exchange Area are projected to exhaust within 12 months of the date of this application and that the months-to-exhaust is documented on an Appendix B submitted to the CNA."
                    />
                    <FormControlLabel
                      control={<Radio value="Yes"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="NPA Jeopardy Condition (see Section 9.5 of the Guidelines): I hereby certify that the telephone numbers in existing CO Code(s) (NXX) as defined above for the Switching Entity/POI or Exchange Area are projected to exhaust within 4 months of the date of this application, or within the period specified in an approved Jeopardy Contingency Plan, and that the months-to-exhaust is documented on an Appendix B submitted to the CNA."
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    Another criterion for the assignment of an Additional Code for
                    Growth to a Switching Entity/POI is certification below that
                    <br />
                    the requirements of Appendix G (Reserved and Held Telephone
                    Numbers) are met
                  </InputLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox name="additionalCodesGrowthCondition" value={props["additionalCodesGrowthCondition"]}
                        onChange={(e) => handleUpdate(e.target.name, e.target.value, true)} />}
                      label="I hereby certify that the quantity of Telephone Numbers Reserved and Held for customers in existing CO Codes assigned to the Switching Entity/POI for the applicable Exchange Area and service complies with Appendix G (Reserved and Held Telephone Numbers)."
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    Appendix B:
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  {
                    appendixB ? <Typography variant="body1" component={"span"}>File uploaded: {appendixB}</Typography> :
                      <FileUploader name="appendixB" fileType={1} token={accessToken} onFileUpload={onAppendixBUpload} accept=".xsl,.xlsx" required={true} maxCount={1} />
                  }
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} className="container-no-border">
                <Typography variant="body1">Not required</Typography>
              </Grid>
            )}

            <Typography variant="h6">
              1.7	Additional CO Code for a Unique Purpose (See Section 4.2 of the Guidelines):
            </Typography>
            {props["codeAssignmentType"] == "Additional Code for Unique Purpose" || props["codeReservationType"] == "Additional Code for Unique Purpose" ? (
              <Grid container spacing={2} className="container">
                <Grid item xs={12}>
                  <Typography component={"span"} variant="body1">
                    A criterion for assignment of an Additional Code for a Unique Purpose is that no CO Code has been assigned for that purpose to the Switching Entity/POI. The Applicant must justify why an additional CO Code is required and explain why existing resources assigned to the applicant cannot satisfy this requirement.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup name="codeUniquePurposeType" defaultValue={props["codeUniquePurposeType"]}>
                    <FormControlLabel
                      sx={{ mb: 2 }}
                      control={<Radio value="Code is necessary for distinct routing, rating or billing purposes"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Code is necessary for distinct routing, rating or billing purposes"
                    />
                    <FormControlLabel
                      control={<Radio value="Code is necessary for reasons other than distinct routing, rating or billing purposes"
                        onChange={(e) => handleUpdate(e.target.name, e.target.value)} />}
                      label="Code is necessary for reasons other than distinct routing, rating or billing purposes"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>
                    Explanation
                  </InputLabel>
                  <TextField
                    name="uniquePurposeExplanation"
                    value={props["uniquePurposeExplanation"]}
                    onChange={(e) => handleUpdate(e.target.name, e.target.value)}
                    fullWidth
                  ></TextField>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} className="container-no-border">
                <Typography variant="body1">Not required</Typography>
              </Grid>
            )}

            <Grid container spacing={1} className="container">
              <Typography variant="h6">
                1.8 Attachments:
              </Typography>
              <Grid item xs={12}>
                {
                  attachments ? <Typography variant="body1" component={"span"}>{attachments}</Typography> :
                    <FileUploader name="attachments" fileType={2} token={accessToken} onFileUpload={onAttachmentsUpload} accept=".xsl,.xlsx,.csv,.pdf" required={false} maxCount={5} />
                }
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </AuthenticatedTemplate>
    </>
  );
}
