import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import React from "react";
import { Box, Stack } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const AccountInfo = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (account && account.name) {
      setName(account.name);
      setEmail(account.username);
    } else {
      setName("");
    }
  }, [account]);

  if (name) {
    return (
      <Stack
        direction="row"
        spacing={1}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <AccountCircle fontSize="large" />
        <Stack direction="column" spacing={0} alignItems="flex-start">
          <Typography variant="subtitle1">{name}</Typography>
        </Stack>
        <Box width="20px"></Box>
      </Stack>
    );
  } else {
    return null;
  }
};

export default AccountInfo;
