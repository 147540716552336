import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Alert, AlertProps, Box, Button, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { appConfig } from "../appConfig";
import { loginRequest } from "../authConfig";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridSlots,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { AppRole, getAppRole } from "../utils/AppRole";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { FormStatus } from "../models/FormStatus";

interface DownloadToolbarProps {
  handleDownloadClick: (isNew: boolean) => void;
}

function DownloadToolbar(props: DownloadToolbarProps) {
  const handleClick = () => {
    props.handleDownloadClick(false);
  };

  const handleNewClick = () => {
    props.handleDownloadClick(true);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<CloudDownloadIcon />} onClick={handleNewClick}>
        Download New
      </Button>
      <Button color="primary" startIcon={<CloudDownloadIcon />} onClick={handleClick}>
        Download All
      </Button>
    </GridToolbarContainer>
  );
}

export function AdminFormFour() {
  const { instance } = useMsal();
  const [rows, setRows] = useState<any>([]);
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDownloadClick = (isNew: boolean) => {
    const fileDate = dayjs(new Date()).format('YYYY-MM-DD hh-mm A')
    const fileName = `Part4-NPA-NXX-${fileDate}.csv`;
    const url = isNew ? "/api/formfour/download/new" : "/api/formfour/download";
    fetch(`${appConfig.api.url}${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Update status to Processed
        const updatedRows = rows.map(row => {
          if (row.status === 0) {
            // Update the status of the found row
            return { ...row, status: 1 };
          }
          return row;
        });
        setRows(updatedRows);

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const handleDownloadDataClick = (id: GridRowId, refNumber: string) => {
    const fileName = `${refNumber}.json`;
    fetch(`${appConfig.api.url}/api/formfour/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Update status to Processed
        const updatedRows = rows.map(row => {
          if (row.id === id && row.status === 0) {
            // Update the status of the found row
            return { ...row, status: 1 };
          }
          return row;
        });
        setRows(updatedRows);

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "referenceNumber",
      headerName: "Ref #",
      width: 150,
    },
    {
      field: "carrierName",
      headerName: "Carrier Name",
      width: 250,
    },
    {
      field: "contactName",
      headerName: "Contact name",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      width: 200,
      valueFormatter: (value) =>
        dayjs(value as string).format('YYYY-MM-DD hh:mm A')
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueFormatter: (value) => FormStatus[value]
    },
    {
      field: "download",
      headerName: "Data",
      width: 125,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<CloudDownloadIcon />}
            onClick={() => handleDownloadDataClick(params.row.id, params.row.referenceNumber)}
          >
            Save
          </Button>
        </strong>
      )
    },
  ];

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    setLoading(true);
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);
        // check Role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role !== AppRole.Administrator) {
          instance.logoutRedirect();
        }

        fetch(`${appConfig.api.url}/api/formfour`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setRows(response);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ height: 750, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }]
              }
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            loading={loading}
            slots={{
              toolbar: DownloadToolbar as GridSlots["toolbar"],
            }}
            slotProps={{
              toolbar: { handleDownloadClick },
            }}
          />
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </AuthenticatedTemplate>
    </>
  );
}
