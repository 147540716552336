import { AuthenticatedTemplate } from "@azure/msal-react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { FormType } from "../models/Constants";
import dayjs from "dayjs";

export function ThankYou() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <AuthenticatedTemplate>
        <Grid container spacing={3} className="container">
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Thank you
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Your data is received and will be processed.<br /><br />
              Reference number: <b>{location.state.referenceNumber ? location.state.referenceNumber : ""}</b><br />
              {location.state.type == FormType.FormOne ? (
                <>
                  Application Type: <b>{location.state.applicationType}</b><br />
                  NPAs: <b>{location.state.npas}</b><br />
                  CO Code preference(s): <b>{location.state.coCodePreferences}</b><br />
                  Exchange Area: <b>{location.state.exchangeArea}</b><br />
                  {location.state.applicationType == "Information Change" &&
                    <>
                      NPA-NXX(s) affected: <b>{location.state.nxxChanged}</b>
                    </>
                  }
                  {location.state.applicationType == "Return" &&
                    <>
                      NPA-NXX(s) returned: <b>{location.state.nxxReturned}</b>
                    </>
                  }
                </>
              ) : (location.state.codes.map((c, i) =>
                <span key={i}>
                  NPA: <b>{c.npa}</b>, NXX: <b>{c.nxx}</b>, OCN: <b>{c.ocn}</b>, Switch Id: <b>{c.switchIdentification}</b>, In-Service Date: <b>{c.inServiceDate}</b><br />
                </span>
              )
              )}
            </Typography>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
}
