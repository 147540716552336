import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import React from "react";
import { FormOne } from "./pages/FormOne";
import { FormFour } from "./pages/FormFour";
import { Upload } from "./pages/Upload";
import { Files } from "./pages/Files";
import { Carriers } from "./pages/Carriers";
import "./styles/app.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThankYou } from "./pages/ThankYou";
import { AdminFormOne } from "./pages/AdminFormOne";
import { AdminFormFour } from "./pages/AdminFormFour";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PageLayout>
          <Grid container justifyContent="center">
            <Pages />
          </Grid>
        </PageLayout>
      </LocalizationProvider>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/form-one" element={<FormOne />} />
      <Route path="/form-four" element={<FormFour />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/files" element={<Files />} />
      <Route path="/carriers" element={<Carriers />} />
      <Route path="/thankyou" element={<ThankYou />} />
      <Route path="/admin-form-one" element={<AdminFormOne />} />
      <Route path="/admin-form-four" element={<AdminFormFour />} />
    </Routes>
  );
}

export default App;
