import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Box, Button, FormHelperText, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { appConfig } from "../appConfig";
import { loginRequest } from "../authConfig";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { AppRole, getAppRole } from "../utils/AppRole";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export function Files() {
  const { instance } = useMsal();
  const [rows, setRows] = useState<any>([]);
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDownload = (id: string, fileName: string) => {
    fetch(`${appConfig.api.url}/api/files/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: "ocn", headerName: "OCN", width: 150 },
    {
      field: "fileName",
      headerName: "File name",
      width: 200,
    },
    {
      field: "carrierName",
      headerName: "Carrier name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      width: 250,
      valueFormatter: (value) =>
        dayjs(value as string).format('YYYY-MM-DD hh:mm A')
    },
    {
      field: "download",
      headerName: "Download",
      width: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            startIcon={<CloudDownloadIcon />}
            onClick={() => handleDownload(params.row.id, params.row.fileName)}
          >
            Download
          </Button>
        </strong>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);

        // check Role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role !== AppRole.Administrator) {
          instance.logoutRedirect();
        }

        fetch(`${appConfig.api.url}/api/files`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setRows(response);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }]
              }
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            loading={loading}
          />
        </Box>
      </AuthenticatedTemplate>
    </>
  );
}
