import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Snackbar,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { loginRequest } from "../authConfig";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { appConfig } from "../appConfig";
import { useNavigate } from "react-router-dom";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import { v4 as uuid } from "uuid";
import { AppRole, getAppRole } from "../utils/AppRole";
import UserInfo from "../models/UserInfo";
import { ContactInformation } from "../components/ContactInformation";
import { FormType } from "../models/Constants";

export function FormFour() {
  const initialProps = {
    id: "",
    address: "",
    administratorEmail: "",
    administratorFax: "",
    administratorName: "",
    administratorPhone: "",
    authorizedRepresentative: "",
    authorizedTitle: "",
    city: "",
    province: "",
    postalCode: "",
    contactName: "",
    email: "",
    entityName: "",
    fax: "",
    codes: [{ ocn: "", npa: "", nxx: "", switchIdentification: "", inServiceDate: dayjs(new Date()).format('YYYY-MM-DD') }],
    phone: "",
    authorizedOCNs: [""],
  };
  const [accessToken, setAccessToken] = useState("");
  const [codes, setCodes] = useState(initialProps.codes);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const form = useRef();
  const [props, setProps] = useState(initialProps);

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);

        // Verify role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role !== AppRole.User) {
          instance.logoutRedirect();
        }

        // Get user info
        fetch(`${appConfig.api.url}/api/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setUserInfo(response);
            if (response.carrier) {
              setProps({ ...props, ...response.carrier });
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance]);

  // Add validation rules
  useEffect(() => {
    ValidatorForm.addValidationRule("isPhoneNumber", (value) => {
      return /^\d{3}-\d{3}-\d{4}$/.test(value);
    });

    ValidatorForm.addValidationRule("ocnRequired", (value) => {
      const ocnCodes = codes.filter((c) => !c.ocn);
      return ocnCodes && ocnCodes.length == 0;
    });

    ValidatorForm.addValidationRule("npaRequired", (value) => {
      const npaCodes = codes.filter((c) => !/^\d{3}/.test(c.npa));
      return npaCodes && npaCodes.length == 0;
    });

    ValidatorForm.addValidationRule("nxxRequired", (value) => {
      const nxxCodes = codes.filter((c) => !/^\d{3}/.test(c.nxx));
      return nxxCodes && nxxCodes.length == 0;
    });

    ValidatorForm.addValidationRule("switchRequired", (value) => {
      const switchCodes = codes.filter((c) => c.switchIdentification.length != 11);
      return switchCodes && switchCodes.length == 0;
    });

    return () => {
      ValidatorForm.removeValidationRule("isPhoneNumber");
      ValidatorForm.removeValidationRule("npaRequired");
      ValidatorForm.removeValidationRule("nxxRequired");
      ValidatorForm.removeValidationRule("switchRequired");
      ValidatorForm.removeValidationRule("ocnRequired");
    }
  });

  // const handleUpdate = (name, value, isCheckbox = false) => {
  //   setProps(props => { return { ...props, [name]: isCheckbox ? value === "on" : value } })
  // }

  const handleCodeUpdate = (name: string, value: string, index) => {
    const updatedCodes = codes.map((c, i) => {
      if (i == index) {
        if (name == "npa") {
          return { npa: value, nxx: codes[i].nxx, ocn: codes[i].ocn, switchIdentification: codes[i].switchIdentification, inServiceDate: codes[i].inServiceDate };
        } else if (name == "nxx") {
          return { npa: codes[i].npa, nxx: value, ocn: codes[i].ocn, switchIdentification: codes[i].switchIdentification, inServiceDate: codes[i].inServiceDate };
        } else if (name == "ocn") {
          return { npa: codes[i].npa, nxx: codes[i].nxx, ocn: value, switchIdentification: codes[i].switchIdentification, inServiceDate: codes[i].inServiceDate };
        } else if (name == "inServiceDate") {
          return { npa: codes[i].npa, nxx: codes[i].nxx, ocn: codes[i].ocn, switchIdentification: codes[i].switchIdentification, inServiceDate: dayjs(value).format("YYYY-MM-DD") };
        } else {
          return { npa: codes[i].npa, nxx: codes[i].nxx, ocn: codes[i].ocn, switchIdentification: value.toUpperCase(), inServiceDate: codes[i].inServiceDate };
        }
      } else {
        return c;
      }
    })
    setCodes(updatedCodes);
    setProps({ ...props, codes: updatedCodes });
  }

  const handleErrors = (errors) => {
    const input = document.querySelector(
      `input[name=${errors[0].props.name}]`
    ) as HTMLElement;

    if (input) {
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      input.focus();
    }
  }

  const handleAddCode = () => {
    setCodes([...codes, { npa: "", nxx: "", ocn: "", switchIdentification: "", inServiceDate: dayjs(new Date()).format('YYYY-MM-DD') }]);
    setProps({ ...props, codes: codes });
  }

  const handleRemoveCode = (i) => {
    codes.splice(i, 1);
    setCodes([...codes]);
    setProps({ ...props, codes: codes });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let data = props;

    // Populate dates
    data["id"] = uuid();
    data["applicationDate"] = formData.get("applicationDate");
    data["entityName"] = data["name"];

    fetch(`${appConfig.api.url}/api/formfour`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        let state = response;
        state.type = FormType.FormFour;
        state.codes = props["codes"].map(c => {
          const result = {
            npa: c.npa,
            nxx: c.nxx,
            ocn: c.ocn,
            switchIdentification: c.switchIdentification,
            inServiceDate: dayjs(c.inServiceDate).format("YYYY-MM-DD")
          };
          return result;
        });
        navigate("/thankyou", { state: state });
      })
      .catch((error) => {
        setSnackbar({ children: error.message, severity: "error" });
      });
  };

  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ width: "75%" }} className="form">
          <ValidatorForm ref={form} onSubmit={handleSubmit} onError={errors => handleErrors(errors)}>
            <Typography variant="h4" align="center">
              Part 4:  Code Holder's Confirmation of Code In-Service Date
            </Typography>

            <Typography variant="h6" sx={{ mt: 3 }}>Code Applicant:</Typography>
            <ContactInformation props={{ ...props }} />

            <Grid container spacing={2} className="container">
              <Grid item xs={12}>
                <InputLabel>Date of Application</InputLabel>
                <DatePicker
                  name="applicationDate"
                  format="YYYY-MM-DD"
                  defaultValue={dayjs(new Date())}
                />
              </Grid>
              {codes.map((c, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={1}>
                    <InputLabel>OCN</InputLabel>
                    <SelectValidator
                      name="ocn"
                      value={props["ocn"]}
                      validators={["ocnRequired"]}
                      errorMessages={["OCN is required"]}
                      onChange={(e) => handleCodeUpdate(e.target.name, e.target.value, index)}
                    >
                      {props.authorizedOCNs.map((o, i) => (
                        <MenuItem value={o} key={i}>{o}</MenuItem>
                      ))}
                    </SelectValidator>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel>
                      NPA code
                    </InputLabel>
                    <TextValidator
                      name="npa"
                      value={props["npa"]}
                      onChange={(e) => handleCodeUpdate(e.target.name, e.target.value, index)}
                      validators={["npaRequired"]}
                      errorMessages={["3 digits required"]}
                      inputProps={{ maxLength: 3 }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel>
                      NXX code
                    </InputLabel>
                    <TextValidator
                      name="nxx"
                      value={props["nxx"]}
                      onChange={(e) => handleCodeUpdate(e.target.name, e.target.value, index)}
                      validators={["nxxRequired"]}
                      errorMessages={["3 digits required"]}
                      inputProps={{ maxLength: 3 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel>
                      Switch Identification{" "}
                      <Tooltip title="This is the 11 character Telcordia® Technologies, Inc. COMMON LANGUAGE® CLLI™ defining the Switching Entity or POI as shown on the Part 1 Form provided by the Code Applicant or Code Holder. Telcordia is a registered trademark and all referenced Telcordia products are trademarks of Telcordia Technologies, Inc. COMMON LANGUAGE is a registered trademark and CLLI is a trademark of Telcordia Technologies, Inc.">
                        <InfoIcon sx={{ fontSize: 24, verticalAlign: "top" }} />
                      </Tooltip>
                    </InputLabel>
                    <TextValidator
                      name="switchIdentification"
                      className="upper"
                      value={props["switchIdentification"]}
                      onChange={(e) => handleCodeUpdate(e.target.name, e.target.value, index)}
                      validators={["switchRequired"]}
                      errorMessages={["11 characters value is required"]}
                      inputProps={{ maxLength: 11 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel>In-Service Date</InputLabel>
                    <DatePicker
                      name="inServiceDate"
                      format="YYYY-MM-DD"
                      value={props["inServiceDate"]}
                      onChange={(e) => handleCodeUpdate("inServiceDate", e, index)}
                    />
                  </Grid>
                  <Grid item xs={1} mt={5}>
                    {index > 0 ?
                      <Button color="primary" startIcon={<RemoveCircleIcon />} onClick={(e) => handleRemoveCode(index)}></Button> : <></>
                    }
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}><Button color="primary" startIcon={<AddIcon />} onClick={handleAddCode}>Add</Button></Grid>
            </Grid>

            <Grid container spacing={2}>
              <InputLabel>By submitting this form, I certify that the CO Code (NXX) specified in Section 1 below is in service and that the CO Code (NXX)<br /> is being used for the purpose specified in the original application (See Section 6.3.3).</InputLabel>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </AuthenticatedTemplate >
    </>
  );
}
