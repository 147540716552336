import * as React from 'react';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from './VisuallyHiddenInput';
import { useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { appConfig } from '../appConfig';

export const FileUploader = ({ name, fileType, token, onFileUpload, accept, required, maxCount }) => {
    const [fileError, setFileError] = useState("");
    const [inProgress, setInProgress] = useState(false);

    const handleFileInput = (e) => {
        setFileError("");

        if (e.target.files.length > maxCount) {
            setFileError(`Only ${maxCount} file(s) allowed`);
            return;
        }

        const formData = new FormData();
        for (var i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            if (file.size > 1048576) {
                setFileError("File size should be less than 1MB.");
                return;
            }

            setInProgress(true);
            // Create FormData object
            formData.append("files", file);
            formData.append("fileType", fileType);
        }

        // Send the file to the server
        fetch(`${appConfig.api.url}/api/files`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((response) => {
                onFileUpload(response);
                setInProgress(false);
            })
            .catch((error) => {
                setFileError("Error uploading file(s)");
                setInProgress(false);
            });
    }

    if (inProgress) {
        return <CircularProgress color="secondary" />
    }

    return (
        <React.Fragment>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                {maxCount > 1 ? "Upload files" : "Upload file"}
                <VisuallyHiddenInput name={name} type="file" required={required} accept={accept} multiple={maxCount > 1} onChange={handleFileInput} />
            </Button>{" "}
            {fileError && <Typography variant='body1' className="error" component={"span"}>{fileError}</Typography>}
        </React.Fragment>
    )
}