import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/styles";
import { red, lightGreen, grey } from "@mui/material/colors";

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: { main: grey[900] },
    secondary: { main: lightGreen[600] },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});