import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authConfig";
import { AppRole, getAppRole } from "../utils/AppRole";
import { appConfig } from "../appConfig";
import { Button, Card, CardActions, CardContent } from "@mui/material";

interface MetricsProps {
  newPart1Count: number,
  newPart4Count: number
}

export function Home() {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState("");
  const [metrics, setMetrics] = useState<MetricsProps>();
  const navigate = useNavigate();

  const handlePart1Click = () => {
    navigate("/admin-form-one");
  };

  const handlePart4Click = () => {
    navigate("/admin-form-four");
  };

  useEffect(() => {
    instance
      .acquireTokenSilent(loginRequest)
      .then(function (accessTokenResponse) {
        // Acquire token interactive success
        setAccessToken(accessTokenResponse.accessToken);
        // check Role
        const role = getAppRole(accessTokenResponse.accessToken);
        if (role == AppRole.Administrator) {

          fetch(`${appConfig.api.url}/api/home`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessTokenResponse.accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              setMetrics(response);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>
        {metrics &&
          <>
            <Card sx={{ minWidth: 300 }} raised={true} style={{ marginRight: 20 }}>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Part 1
                </Typography>
                <Typography variant="body1" color={metrics.newPart1Count > 0 ? "success.dark" : "text.secondary"}>
                  {metrics.newPart1Count} new submissions
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" onClick={handlePart1Click}>View</Button>
              </CardActions>
            </Card>
            <Card sx={{ minWidth: 300 }} raised={true}>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Part 4
                </Typography>
                <Typography variant="body1" color={metrics.newPart4Count > 0 ? "success.dark" : "text.secondary"}>
                  {metrics.newPart4Count} new submissions
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="medium" onClick={handlePart4Click}>View</Button>
              </CardActions>
            </Card>
          </>
        }
      </AuthenticatedTemplate>
    </>
  );
}
