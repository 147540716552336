import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: "ec3b73a0-88b3-40d8-8b68-2b765ea860ba",
        authority: "https://login.microsoftonline.com/c2bf2757-df43-454d-a08b-72353e636123",
        redirectUri: "https://secureportal-central-prod-apsfe.azurewebsites.net",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["api://ec3b73a0-88b3-40d8-8b68-2b765ea860ba/groups_read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
