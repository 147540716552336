import { Grid, InputLabel } from "@mui/material"
import React from "react";
import { TextValidator } from "react-material-ui-form-validator";

export const ContactInformation = (props) => {
    const info = props.props;

    return (
        <Grid container spacing={2} className="container">
            <Grid item xs={12}>
                <InputLabel>Code Applicant or Code Holder</InputLabel>
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Entity Name</InputLabel>
                <TextValidator
                    name="entityName"
                    value={info["name"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Contact Name</InputLabel>
                <TextValidator
                    name="contactName"
                    value={info["contactName"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Address</InputLabel>
                <TextValidator
                    name="address"
                    value={info["address"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>City</InputLabel>
                <TextValidator
                    name="city"
                    value={info["city"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Province</InputLabel>
                <TextValidator
                    name="province"
                    value={info["province"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Postal Code</InputLabel>
                <TextValidator
                    name="postalCode"
                    value={info["postalCode"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Telephone</InputLabel>
                <TextValidator
                    name="phone"
                    value={info["phone"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Facsimile</InputLabel>
                <TextValidator
                    name="fax"
                    value={info["fax"]}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Email</InputLabel>
                <TextValidator
                    name="email"
                    value={info["email"]}
                    disabled
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}